import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import ConversationView from "./ConversationView";
import {
  SetParticipantsType,
  SetSidType,
  SetUnreadMessagesType,
} from "../../../types";
import { Client } from "@twilio/conversations";
import { actionCreators, AppState } from "../../../store";
import { getTypingMessage, unexpectedErrorNotification } from "../helpers";
import { ReduxConversation } from "../../../store/reducers/convoReducer";
import { getSdkConversationObject } from "../conversations-objects";
import { ReduxMessage } from "../../../store/reducers/messageListReducer";
import { addConversation, checkUserByUniqueName } from "../api";
import { filterConversations } from "../../../store/action-creators";
import { UserInterface } from "../../../types/types";
import styles from "../styles";
import { useUser } from "../../../context/UserContext";

function getLastMessage(messages: ReduxMessage[], typingData: string[]) {
  if (messages === undefined || messages === null) {
    return "Loading...";
  }
  if (typingData.length) {
    return "Typing...";
  }
  if (messages.length === 0) {
    return "No messages";
  }
  return messages[messages.length - 1].body || "Media message";
}

function isMyMessage(messages: ReduxMessage[]) {
  if (messages === undefined || messages === null || messages.length === 0) {
    return false;
  }
  return messages[messages.length - 1].author ===
    localStorage.getItem("username")
    ? messages[messages.length - 1]
    : false;
}

async function updateCurrentConvo(
  setSid: SetSidType,
  convo: ReduxConversation,
  updateParticipants: SetParticipantsType
) {
  setSid(convo.sid);

  const participants = await getSdkConversationObject(convo).getParticipants();
  updateParticipants(participants, convo.sid);
}

function setUnreadMessagesCount(
  currentconvoSid: string,
  convoSid: string,
  unreadMessages: Record<string, number>,
  updateUnreadMessages: SetUnreadMessagesType
) {
  if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
    updateUnreadMessages(convoSid, 0);
    return 0;
  }
  if (currentconvoSid == convoSid) {
    return 0;
  }
  return unreadMessages[convoSid];
}

interface ConvosContainerProps {
  client?: Client;
  searchString: string,
  showConversationFor: string,
  setUserSearchString: any
}

const ConversationsList = (props: ConvosContainerProps) => {
  const sid = useSelector((state: AppState) => state.sid);
  const conversations = useSelector((state: AppState) => state.convos);
  const messages = useSelector((state: AppState) => state.messages);
  const unreadMessages = useSelector((state: AppState) => state.unreadMessages);
  const participants = useSelector((state: AppState) => state.participants);
  const typingData = useSelector((state: AppState) => state.typingData);
  const [loading, setLoading] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const use24hTimeFormat = useSelector(
    (state: AppState) => state.use24hTimeFormat
  );
  const user = useUser();
  const [filteredConversations, setFilteredConversations] = useState<ReduxConversation[]>([]);
  const [oConversations, setOConversations] = useState<any>([]);

  const dispatch = useDispatch();
  const {
    updateCurrentConversation,
    updateParticipants,
    updateUnreadMessages,
    setLastReadIndex,
    addNotifications,
    upsertConversation
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    const sum = Object.values(unreadMessages).reduce(
      (acc: number, value: number) => acc + value,
      0
    );
    // document.title = sum >= 1 ? `(${sum}) Messages` : "Messages";

    return () => {
      // document.title = 'Lutefish';
      new AbortController().abort()
    };
  }, [unreadMessages]);


  useEffect(() => {
   
    const fetchData = async () => {
      try {
        //  setLoading(true);
        // console.log("in here", conversations, props.showConversationFor);
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connections/message/emails`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionId")}`,
          },
        });
        if (res.status > 400) {
          throw new Error("api_error");
        }

        const data = await res.json();

        const newFilteredConversations = conversations.filter((conversation) => {
          const emails = extractEmails(conversation.uniqueName);
      
          const matchingEmail = emails.find((email: any) => {
              if (isSameEmail(email, user.email)) {
                  return data.some((apiEmail: any) => isSameEmail(emails.find((e:any) => e !== email), apiEmail));
              }
              if (data.some((apiEmail: any) => isSameEmail(email, apiEmail))) {
                  return isSameEmail(emails.find((e:any) => !isSameEmail(e, user.email)), user.email);
              }
              return false;
          });
          return matchingEmail;
      });
      setFilteredConversations(newFilteredConversations);
      if (props.showConversationFor === 'message') {
        setFilteredConversations(newFilteredConversations.filter(conv => !conv.uniqueName?.includes('GIG@@@') && !conv.friendlyName?.includes('JAM@@@') && conv.uniqueName?.includes('@')))
      } else if (props.showConversationFor === "gigs") {
        setFilteredConversations(newFilteredConversations.filter(conv => conv.uniqueName?.includes('GIG@@@')))
      } else {
        setFilteredConversations(newFilteredConversations.filter(conv => !conv.friendlyName?.includes('JAM@@@') && conv.uniqueName?.includes('@')));
        }

      } catch (error) {
      }
    };
    
    if(conversations?.length > 0) {
      fetchData();
    }
  }, [conversations, props.showConversationFor]);

  useEffect(() => {
    let convoList: any = [];
    filteredConversations.filter((convo: any) => {
      // ADDED FILTER TO SKIP THE JAM SESSION CONVERSATIONS
      if (convo.friendlyName && !convo.friendlyName.includes('JAM@@@') && convo.uniqueName?.includes('@')) {
        convoList.push(convo);
      }
    })
    setFilteredConversations(convoList);
    setTimeout(() => {
      if (filteredConversations.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
      setLoading(false);
    }, 3000);
  }, [conversations])

  function isSameEmail(email: any, currentUserEmail: any) {
    return email === currentUserEmail;
  }

  function extractEmails(uniqueName: any) {
    return uniqueName.split("-");
  }

  return (
    <div id="conversation-list">
      {/* con{JSON.stringify(conversations)} */}
      {
        loading ?
          <div className="loading" style={styles.emptyConvo}>Loading...</div> :
          filteredConversations.length > 0 ?
          filteredConversations.map((convo: any) => (

              <ConversationView
                use24hTimeFormat={use24hTimeFormat}
                key={convo.sid ? convo.sid : convo.uniqueName}
                convoId={convo.sid}
                setSid={updateCurrentConversation}
                currentConvoSid={sid}
                lastMessage={
                  (convo.sid === null) ? "No Message" : getLastMessage(messages[convo.sid], typingData[convo.sid] ?? []) ??
                    ""
                }
                messages={messages[convo.sid]}
                typingInfo={typingData[convo.sid] ?? []}
                myMessage={isMyMessage(messages[convo.sid])}
                unreadMessagesCount={setUnreadMessagesCount(
                  sid,
                  convo.sid,
                  unreadMessages,
                  updateUnreadMessages
                )}
                updateUnreadMessages={updateUnreadMessages}
                participants={participants[convo.sid] ?? []}
                convo={convo}
                onClick={async () => {
                  // props.setUserSearchString("");
                  // return false;
                  try {
                    var convoDetails: any;

                    if (convo.sid === null) {
                      const createResponse = await addConversation(
                        convo.friendlyName as string,
                        // 'Sangam Pandeyy',
                        convo.uniqueName as string,
                        updateParticipants,
                        props.client,
                        addNotifications
                      )


                      // conversations.map((con) => {
                      //   if(con.uniqueName === convo.uniqueName){
                      //     convo.sid = createResponse.sid;
                      //   }
                      // });
                      // @ts-ignore
                      // document.getElementById("convoString")?.value = '';
                      // dispatch(filterConversations("", props.filteredActiveUsers) as any);
                      updateCurrentConversation(createResponse.sid);
                      // upsertConversation({...convo, sid: createResponse.sid});
                      // updateConversation(createResponse.sid, {...convo, sid: createResponse.sid});
                      // conversations = [];
                      // props.searchStringRef.current.value = '';
                      props.setUserSearchString("");

                      // setLastReadIndex(convoDetails.lastReadMessageIndex ?? -1);
                      await updateCurrentConvo(
                        updateCurrentConversation,
                        { ...convo, sid: createResponse.sid },
                        updateParticipants
                      );
                      //update unread messages
                      updateUnreadMessages(createResponse.sid, 0);
                      const lastMessage =
                        messages[createResponse.sid].length && messages[createResponse.sid][messages[createResponse.sid].length - 1];
                      if (lastMessage && lastMessage.index !== -1) {
                        await getSdkConversationObject(
                          { ...convo, sid: createResponse.sid }
                        ).advanceLastReadMessageIndex(lastMessage.index);
                      }
                    } else {
                      convoDetails = convo;

                      setLastReadIndex(convoDetails.lastReadMessageIndex ?? -1);
                      await updateCurrentConvo(
                        updateCurrentConversation,
                        convoDetails,
                        updateParticipants
                      );
                      //update unread messages
                      updateUnreadMessages(convoDetails.sid, 0);
                      //set messages to be read
                      const lastMessage =
                        messages[convoDetails.sid].length &&
                        messages[convoDetails.sid][messages[convoDetails.sid].length - 1];
                      if (lastMessage && lastMessage.index !== -1) {
                        await getSdkConversationObject(
                          convo
                        ).advanceLastReadMessageIndex(lastMessage.index);
                      }
                    }


                  } catch (e: any) {
                    unexpectedErrorNotification(e.message, addNotifications);
                  }
                }}
              />
            ))
            :
            isEmpty && !loading &&
            <div className="empty" style={styles.emptyConvo}>Empty Conversations</div>
      }

      {/* New available connections {props.searchString}
      {/*RENDER THE LIST FOR THE USER WITHOUT CONVERSATION YET THEY MATCH WITH THE ACTIVE USERS  */}
      {/* {
        props.searchString.length > 0 ?
        <>
          {props.filteredActiveUsers.map(activeUser => {
            var convo: ReduxConversation = {
              dateUpdated: null,
              // friendlyName: user.name,
              // uniqueName: user.userEmail,
              friendlyName: activeUser.firstName + ' ' + activeUser.lastName,
              uniqueName: activeUser.email,
              lastMessage: {},
              lastReadMessageIndex: null,
              notificationLevel: "default",
              sid: ''
            };
 
            if((activeUser.firstName && activeUser.firstName.toLowerCase().includes(props.searchString.toLowerCase())) || (activeUser.lastName && activeUser.lastName.toLowerCase().includes(props.searchString.toLowerCase()) )) {
              // var u:any = checkConversationPresent(activeUser.email)
              var u:any = conversations.find((convo:any) => {
                return convo.uniqueName != null && convo.uniqueName == activeUser.email
              })
 
              if(u == undefined){
                return <ConversationView
                    use24hTimeFormat={use24hTimeFormat}
                    key={convo.sid ? convo.sid : convo.uniqueName}
                    convoId={''}
                    setSid={updateCurrentConversation}
                    currentConvoSid={sid}
                    lastMessage={"Start New conversation"}
                    // messages={messages[convo.sid]}
                    messages={[]}
                    typingInfo={[]}
                    // myMessage={isMyMessage(messages[convo.sid])}
                    myMessage={false}
                    unreadMessagesCount={0}
                    updateUnreadMessages={updateUnreadMessages}
                    participants={[]}
                    convo={convo}
                    onClick={async () => {
                      // props.setUserSearchString("");
                      // return false;
                      try {
                        var convoDetails:any;
                        if(convo.sid === null || convo.sid === ""){
                          const createResponse = await addConversation(
                            convo.friendlyName as string,
                            // 'Sangam Pandeyy',
                            convo.uniqueName as string,
                            updateParticipants,
                            props.client,
                            addNotifications
                          )
   
                          // conversations.map((con) => {
                          //   if(con.uniqueName === convo.uniqueName){
                          //     convo.sid = createResponse.sid;
                          //   }
                          // });
                          // @ts-ignore
                          // document.getElementById("convoString")?.value = '';
                          // dispatch(filterConversations("", props.filteredActiveUsers) as any);
                          updateCurrentConversation(createResponse.sid);
                          // upsertConversation({...convo, sid: createResponse.sid});
                          // updateConversation(createResponse.sid, {...convo, sid: createResponse.sid});
                          // conversations = [];
                          // props.searchStringRef.current.value = '';
                          props.setUserSearchString("");
   
                          // // setLastReadIndex(convoDetails.lastReadMessageIndex ?? -1);
                          // await updateCurrentConvo(
                          //   updateCurrentConversation,
                          //   {...convo, sid: createResponse.sid},
                          //   updateParticipants
                          // );
                          // //update unread messages
                          // updateUnreadMessages(createResponse.sid, 0);
                          // const lastMessage =
                          //   messages[createResponse.sid].length && messages[createResponse.sid][messages[createResponse.sid].length - 1];
                          // if (lastMessage && lastMessage.index !== -1) {
                          //   await getSdkConversationObject(
                          //     {...convo, sid: createResponse.sid}
                          //   ).advanceLastReadMessageIndex(lastMessage.index);
                          // }
                        }else{
                          convoDetails = convo;
                         
                          setLastReadIndex(convoDetails.lastReadMessageIndex ?? -1);
                          await updateCurrentConvo(
                            updateCurrentConversation,
                            convoDetails,
                            updateParticipants
                          );
                          //update unread messages
                          updateUnreadMessages(convoDetails.sid, 0);
                          //set messages to be read
                          const lastMessage =
                            messages[convoDetails.sid].length &&
                            messages[convoDetails.sid][messages[convoDetails.sid].length - 1];
                          if (lastMessage && lastMessage.index !== -1) {
                            await getSdkConversationObject(
                              convo
                            ).advanceLastReadMessageIndex(lastMessage.index);
                          }
                        }
   
   
                      } catch (e:any) {
                        unexpectedErrorNotification(e.message, addNotifications);
                      }
                    }}
                  />
              }
            }
          })}
        </>
        : <></>
      } */}
    </div>
  );
};

export default ConversationsList;